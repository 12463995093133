import { UserService } from "../../domain/services/UserService";
import { userMapperFactory } from "../mappers/UserMapper";
import { conciliumLink, skipServerUtcToLocalConversion } from "../../Config";
import { get } from "../../tools/Tools";
import * as _ from "lodash";
import { User } from "../../domain/models/User";

export class UserServiceImpl implements UserService {
    private static instance: UserService;
    private _user: User;

    private constructor() {}

    get user(): User {
        return this._user;
    }

    static getInstance() {
        if (!UserServiceImpl.instance) {
            UserServiceImpl.instance = new UserServiceImpl();
        }
        return UserServiceImpl.instance;
    }

    async getUser() {
        return userMapperFactory(skipServerUtcToLocalConversion).fromDTO(
            _.get(await get(`${conciliumLink || ""}/api/user`), "data")
        );
    }

    async getCachedUser(): Promise<User> {
        if (!this._user) {
            this._user = await this.getUser();
        }
        return this._user;
    }
}

export function userServiceFactory(): UserService {
    return UserServiceImpl.getInstance();
}
