export enum Errors {
    WRONG_USER_DATA = "WRONG_USER_DATA",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    PROMOCODE_NOT_FOUND = "PROMOCODE_NOT_FOUND",
    PROMOCODE_IS_NOT_ACTIVE = "PROMOCODE_IS_NOT_ACTIVE",
    PROMOCODE_IS_NOT_APPLICABLE = "PROMOCODE_IS_NOT_APPLICABLE",
    PROMOCODE_LIMIT_ERROR = "PROMOCODE_LIMIT_ERROR",
    PROMOCODE_WITHDRAWAL_RESTRICTIONS = "PROMOCODE_WITHDRAWAL_RESTRICTIONS",
    PROMOCODE_START_ERROR = "PROMOCODE_START_ERROR",
    PROMOCODE_END_ERROR = "PROMOCODE_END_ERROR",
    PROMOCODE_ALREADY_APPLIED_ERROR = "PROMOCODE_ALREADY_APPLIED_ERROR",
    PROMOCODE_BONUS_ALREADY_APPLIED_ERROR = "PROMOCODE_BONUS_ALREADY_APPLIED_ERROR",

    PROMOCODE_START_DATE_EDIT_PROHIBITED = "PROMOCODE_START_DATE_EDIT_PROHIBITED",
    PROMOCODE_END_DATE_EDIT_PROHIBITED = "PROMOCODE_END_DATE_EDIT_PROHIBITED",
    PROMOCODE_VALUE_EDIT_PROHIBITED = "PROMOCODE_VALUE_EDIT_PROHIBITED",
    PROMOCODE_TYPE_EDIT_PROHIBITED = "PROMOCODE_TYPE_EDIT_PROHIBITED",
    PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED = "PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED",
    PROMOCODE_NAME_IS_NOT_UNIQUE = "PROMOCODE_NAME_IS_NOT_UNIQUE",

    USER_SPECIALIST_NOT_FOUND = "USER_SPECIALIST_NOT_FOUND",
    USER_SPECIALIST_DELETION_PROHIBITED = "USER_SPECIALIST_DELETION_PROHIBITED",
    USER_SPECIALIST_HAS_CONCILIUMS = "USER_SPECIALIST_HAS_CONCILIUMS",

    TRANSACTION_RECHARGE_ERROR = "TRANSACTION_RECHARGE_ERROR",

    USER_SPECIALIST_DOES_NOT_EXISTS = "USER_SPECIALIST_DOES_NOT_EXISTS",
    CAN_NOT_ADD_SPECIALIST = "CAN_NOT_ADD_SPECIALIST",
    ADD_MED_SPECIALIST_FIRST = "ADD_MED_SPECIALIST_FIRST",
    TRANSLATOR_ALREADY_ADDED = "TRANSLATOR_ALREADY_ADDED",
    NEED_MORE_MED_SPECIALISTS = "NEED_MORE_MED_SPECIALISTS",
    ADMINISTRATOR_ALREADY_ADDED = "ADMINISTRATOR_ALREADY_ADDED",
    SPECIALIST_ALREADY_ADDED = "SPECIALIST_ALREADY_ADDED",
    ERROR_ADDING_SPECIALIST = "ERROR_ADDING_SPECIALIST",
    MAX_PARTICIPANTS_NUMBER_REACHED = "MAX_PARTICIPANTS_NUMBER_REACHED",

    CART_IS_EMPTY = "CART_IS_EMPTY",
    CART_TRANSLATOR_REQUIRED = "CART_TRANSLATOR_REQUIRED",
    CART_ADMINISTRATOR_REQUIRED = "CART_ADMINISTRATOR_REQUIRED",

    CART_STATUS_IS_NOT_DEFINED = "CART_STATUS_IS_NOT_DEFINED",
    CART_CAN_NOT_SET_STATUS = "CART_CAN_NOT_SET_STATUS",
    CART_PROCESS_IS_NOT_FINISHED = "CART_PROCESS_IS_NOT_FINISHED",
    CART_SPECIALISTS_TIME_DOES_NOT_MATCH = "CART_SPECIALISTS_TIME_DOES_NOT_MATCH",
    CART_SMALL_TIME_RESERVE = "CART_SMALL_TIME_RESERVE",
    CART_MEETING_TIME_IS_NOT_SET = "CART_MEETING_TIME_IS_NOT_SET",

    CONCILIUM_CREATION_ERROR = "CONCILIUM_CREATION_ERROR",

    INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",

    UNAUTHORIZED = "UNAUTHORIZED",
}
