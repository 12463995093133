import { Mapper } from "../domain/mappers/mapper";
import { UserSpecialistDTO } from "../domain/models/dto/UserSpecialistDTO";
import { UserSpecialist } from "../domain/models/UserSpecialist";
import { UserSpecialistModel } from "../models/UserSpecialistModel";
import * as _ from "lodash";
import { userMapperFactory } from "../concilium/mappers/UserMapper";
import { SimpleMapper } from "./SimpleMapper";

export class UserSpecialistMapper extends SimpleMapper<
    UserSpecialist,
    UserSpecialistDTO
> {
    protected fromDTOFields: string[] = ["id", "status", "specialistType"];

    protected skipServerUtcToLocalConversion: boolean;

    constructor(skipServerUtcToLocalConversion: boolean) {
        super();
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
    }

    fromDTO(dto: UserSpecialistDTO): UserSpecialist {
        if (!dto) {
            return null;
        }

        const model = super.fromDTO(dto);

        return new UserSpecialistModel({
            ...model,
            specialty: dto.specialty || {
                title: "",
            },
            user: userMapperFactory(
                this.skipServerUtcToLocalConversion
            ).fromDTO(dto.user),
        });
    }
}

export function userSpecialistMapperFactory(
    skipServerUtcToLocalConversion: boolean
): Mapper<UserSpecialist, UserSpecialistDTO> {
    return new UserSpecialistMapper(skipServerUtcToLocalConversion);
}
