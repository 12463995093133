import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "../../translations";
import cookie from "react-cookies";
import BecameASpecialistButton from "./BecameASpecialistButton";

i18n.use(initReactI18next).init({
    resources: translations,
    lng: cookie.load("locale") || "ru",
});

initButton("becameASpecialistButton");
initButton("becameASpecialistButtonMobile");
initButton("becameASpecialistButtonFooter", "footer__link calendarShow");

function initButton(containerId: string, buttonClassName?: string) {
    const container = document.getElementById(containerId);

    if (container) {
        ReactDOM.render(<BecameASpecialistButton buttonClassName={buttonClassName} />, container);
    } else {
        console.log(`initButton => container not found: ${containerId}`);
    }
}
