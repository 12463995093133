import * as _ from "lodash";
import { post, get, deleteRequest } from "../tools/Tools";
import { conciliumLink, skipServerUtcToLocalConversion } from "../Config";
import { UserSpecialistService } from "../domain/services/UserSpecialistService";
import { PromocodeValidationDTO } from "../domain/models/dto/PromocodesDTO";
import { UserSpecialistDTO } from "../domain/models/dto/UserSpecialistDTO";
import { userSpecialistMapperFactory } from "../mappers/UserSpecialistMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { UserSpecialistModel } from "../models/UserSpecialistModel";

export class UserSpecialistServiceImpl extends BaseServiceImpl implements UserSpecialistService {
    mapper = userSpecialistMapperFactory(skipServerUtcToLocalConversion);
    async createUserSpecialist(
        specType: number
    ): Promise<UserSpecialistDTO> {
        if (!specType) {
            return;
        }
        await post(`${conciliumLink}/api/user-specialist`, {
            specType: specType,
        });
    }

    async getPromocodeValidation(
        specialistId: string
    ): Promise<PromocodeValidationDTO> {
        if (!specialistId) {
            return;
        }
        return _.get(
            await get(`${conciliumLink}/promocode/validation?specialistId=${specialistId}`),
            "data"
        );
    }

    async removeSpecRequest(): Promise<void> {
        return deleteRequest(`${conciliumLink}/api/user-specialist`);
    }

    async getUserSpecialist(): Promise<UserSpecialistModel> {
        return this.mapper.fromDTO(this.getData(await get(`${conciliumLink}/api/user-specialist`)));
    }
}

export function userSpecialistServiceFactory(): UserSpecialistService {
    return new UserSpecialistServiceImpl();
}
