import { environment } from "./environments/environment";
import * as _ from "lodash";

export const conciliumLink = environment.CONCILIUM_LINK;
export const adminApiURL = environment.ADMIN_API_URL;
export const allowConferenceRestart =
    _.get(environment, "ALLOW_CONFERENCE_RESTART", "").toLowerCase() === "true";

export const paypalClientId = environment.PAYPAL_CLIENT_ID;
export const allowUsePromocodes =
    _.get(environment, "ALLOW_USE_PROMOCODES", "").toLowerCase() === "true";

/**
 * If true that mean that back-end will expect dates to be already converted to UTC.
 * Otherwise it will convert dates to UTC basic on the user timezone, defined in User model.
 */
export const skipServerUtcToLocalConversion =
    _.get(
        environment,
        "SKIP_SERVER_UTC_TO_LOCAL_CONVERSION",
        ""
    ).toLowerCase() === "true";
