import * as queryString from "query-string";
import { ErrorServiceFactory } from "../services/ErrorService";

const errorService = ErrorServiceFactory();

export async function get(url: string) {
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });
    return response.json();
}

export async function patch(url: string, data?: any, method: string = "PATCH") {
    const response = await fetch(url, {
        method,
        credentials: "include",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        await errorService.throwResponseError(response);
    }
    return response.json();
}

export async function post(url: string, data: any) {
    return patch(url, data, "POST");
}

export async function deleteRequest(url: string, data?: any) {
    return patch(url, data, "DELETE");
}

export function getQueryParams() {
    return queryString.parse(window.location.search);
}

export function getDateLocale(date) {
    return new Date(date).toLocaleDateString("en-US", {
        localeMatcher: "best fit",
        hour12: false,
        timeZone: "GMT",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
}
