import _ from "lodash";
import React from "react";

export interface ModalWrapperProps {
    children: any;
}

export default function ModalWrapper(props: ModalWrapperProps) {
    return <div className="modal active">{..._.isArray(props.children) ? props.children : [props.children]}</div>;
}
