import { SpecialistType } from "../domain/enums/SpecialistType";
import { UserSpecialistStatus } from "../domain/enums/UserSpecialistStatus";
import { SpecialtyDTO } from "../domain/models/dto/SpecialtyDTO";
import { User } from "../domain/models/User";
import { UserSpecialist } from "../domain/models/UserSpecialist";

export class UserSpecialistModel implements UserSpecialist {
    id?: string;
    status?: UserSpecialistStatus;

    specialistType?: SpecialistType;
    specialty?: SpecialtyDTO;
    user?: User;

    constructor(data: UserSpecialist) {
        Object.assign(this, data || {});
    }

    get isCreated(): boolean {
        return this.status === UserSpecialistStatus.CREATED;
    }

    get isApproved(): boolean {
        return this.status === UserSpecialistStatus.APPROVED;
    }

    get isNew() {
        return !!this.id;
    }

    get isMedicalSpecialist() {
        return this.specialistType == SpecialistType.MEDICAL_SPECIALIST;
    }

    get isAdministrator() {
        return this.specialistType == SpecialistType.ADMINISTRATOR;
    }

    get isTranslator() {
        return this.specialistType == SpecialistType.TRANSLATOR;
    }
}
