import moment from "moment-timezone";
import { DATETIME_FORMAT } from "../../domain/constants/Constants";
import { UserDTO } from "../../domain/models/dto/UserDTO";
import { User } from "../../domain/models/User";

export class UserModel implements User {
    balance: number;
    isAnonymous: boolean;

    name?: string;
    surname?: string;
    timezone?: string;
    languages?: string[];

    skipServerUtcToLocalConversion: boolean = false;

    constructor(data: UserDTO, skipServerUtcToLocalConversion: boolean) {
        Object.assign(this, data || {});
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
    }

    get fullName() {
        return `${this.name} ${this.surname}`;
    }

    getLanguageFilterStr(): string {
        return this.languages.reduce((acc, current) => {
            return (acc += "&language=" + current);
        }, "");
    }

    getUserCurrentTime(): moment.Moment {
        /**
         * In case if needed to define user's time by user's timezone,
         * need to get user time within user's timezone and recreate it in local browsers time
         */
        return !this.skipServerUtcToLocalConversion && this.timezone
            ? moment(moment().tz(this.timezone).format(DATETIME_FORMAT))
            : moment();
    }
}

export function userFactory(
    data: UserDTO,
    skipServerUtcToLocalConversion: boolean
): User {
    return new UserModel(data, skipServerUtcToLocalConversion);
}
