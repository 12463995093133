import { ConciliumStatus } from "./domain/enums/ConciliumStatus";

export const translations = {
    uk: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Замовити консультацію",
                createConcilium: "Скликати консиліум",
                dateTime: {
                    concilium: "Дата та час проведення консиліуму",
                    consultation: "Дата та час проведення консультації",
                    at: "о",
                    edit: "Редагувати",
                    choiseDateTime: "Виберіть дату та час",
                    conciliumLabel: "консиліуму",
                    consultationLabel: "консультації",
                    dateTimeText:
                        "У таблиці відображається дата та час, який підходить для всіх учасників",
                    duration: "Тривалість",
                    hour_1: "1 година",
                    hour_2: "2 години",
                    hour_3: "3 години",
                    warning: "Ви не обрали час",
                    selectedRangeIsNotValid: "Вибраний час консультації некоректний"
                },
                details: {
                    step_1: "Крок 1.",
                    basic: "Основне",
                    step_3: "Крок 3",
                    payment: "Оплата",
                    proposal: "Заявка на ",
                    concilium: "консиліум",
                    consultation: "консультацію",
                    placeholder: "Опишіть причини звернення",
                    addFile: "Прикріпити файл",
                    addAudio: "Запис звуку",
                    addVideo: "Запис відео",
                },
                extra: {
                    text: 'Для створення консультації/консиліуму Ви обрали час, який тарифікується спеціалістами як "Термінова"!',
                    next: "Продовжити",
                    prev: "Вибрати інший час",
                },
                latest: {
                    concilium: "Консиліум",
                    consultation: "Консультація",
                    text_1: "Ваша заявка на консультацію від ",
                    text_2: "року успішно направлена, і буде розглянута найближчим часом.",
                },
                payment: {
                    step_2: "Крок 2.",
                    detailsText: "Деталі",
                    consultationText: "Консультації (Кількість: ",
                    translatorText: "Послуги перекладача (Кількість: ",
                    adminText: "Послуги адміністратора (Кількість: ",
                    allText: "Усього",
                    warningText:
                        "У вас недостатньо коштів, щоб замовити вибрану консультацію/консиліум. Для завершення (продовження) оформлення замовлення просимо поповнити свій рахунок у валюті сайту - Medical Concilium Credits.",
                    warningButton: "поповнити баланс",
                    confirm:
                        "Ви підтверджуєте свій контракт, згідно з яким Ви повинні здійснити покупку послуг, перерахованих вище. Ви також підтверджуєте, що Ви маєте повну право- та дієздатність для укладення та виконання контракту, і Ви прочитали, розумієте та згодні з Умовами надання послуг та Політикою конфіденційності сайту Medical Concilium.",
                    next: "Оформити замовлення",
                },
                requiredAdmin: {
                    admin: "Адміністратор консиліуму",
                    adminHeader: "Потрібен Адміністратор консиліуму!",
                    adminText:
                        "Для покращення якості та допомоги у консультуванні пропонуємо скористатися послугами Адміністратора консиліуму. Відповідно до Умов надання послуг та Політики конфіденційності Medical Concilium, відмова від послуг Адміністратора консиліуму покладає всі зобов'язання щодо якості та повноти його проведення на ініціатора консиліуму.",
                    adminButton: "Перейти до вибору Адміністратора",
                },
                requiredLang: {
                    lang: "Перекладач",
                    langHeader: "Потрібен Перекладач!",
                    langText:
                        "Ви обрали для консультації спеціаліста, мова спілкування якого не співпадає з Вашою.",
                    langButton: "Перейти до вибору Перекладача",
                    specButton: "Вибрати іншого Медичного спеціаліста",
                },
                requiredSpec: {
                    specText:
                        "Для оформлення заявки на Консиліум/Консультацію необхідно вибрати медичних фахівців.",
                },
                smallTime: {
                    text_1: "До початку консиліуму залишилося менше",
                    text_2: "хвилин, будь ласка, виберіть інший час",
                    cancel: "Скасувати",
                    changeTime: "Вибрати інший час",
                },
                specList: {
                    header: "Редагування",
                    remove: "Виключити спеціаліста",
                    add: "Додати",
                    spec: "спеціаліста",
                    search: "Продовжити пошук фахівців",
                    next: "Готово",
                },
            },

            promocodesTabHeader: {
                header: "Промокоди",
            },

            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Кількість бонусів від",
                    valueEnd: "Кількість бонусів до",
                    createdBegin: "Створено від (UTC)",
                    createdEnd: "Створено до (UTC)",
                    isActive: {
                        all: "Усе",
                        yes: "Активні",
                        no: "Неактивні",
                    },
                    reset: "Скинути",
                    filter: "Відфільтрувати",
                    ready: "Готово",
                },
                promocodeAddModal: {
                    header: "Створити промокод",
                    changePromocodeHeader: "Змінити промокод",
                    categoryHeader: "Доступний категоріям",
                    changeName: "Змінити",
                    submit: "Відправити",
                    validation: {
                        required: "Обов'язкове поле",
                        integer: "Тільки ціле значення",
                        min1: "Мінімальне значення - 0,01",
                        min2: "Мінімальне значення - 0",
                        existingName: "Таке ім'я вже існує",
                        namePattern:
                            "Тільки латиниця верхнього регістру та цифри 0-9 (10 символів)",
                        endDate:
                            "Час завершення не може передувати часу початку",
                        exact8Characters: "Максимум 8-значне число",
                        number: "Це повинно бути число",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Створити промокод",
                    buttonChangeName: "Змінити",
                },
                promocodeFields: {
                    name: "Назва",
                    type: "Тип",
                    startDate: "Початок дії",
                    endDate: "Кінець дії",
                    startDateUTCFrom: "Початок дії (UTC) від",
                    endDateUTCFrom: "Кінець дії (UTC) від",
                    startDateUTCBefore: "Початок дії (UTC) до",
                    endDateUTCBefore: "Кінець дії (UTC) до",
                    value: "Кількість бонусів",
                    isActive: {
                        isActiveHeader: "Активний",
                        yes: "так",
                        no: "ні",
                    },
                    userTypes: {
                        userTypesHeader: "Типи користувачів",
                        MedicalSpecialist: "Медичний спеціаліст",
                        Translator: "Перекладач",
                        Administrator: "Адміністратор",
                    },
                    usagesLimit: "Ліміт використання",
                    created: "Створений (UTC)",
                    updated: "Оновлений (UTC)",
                    usages: "Використаний",
                    actions: "Дії",
                },
            },
            promocodesPagination: {
                label: "Кількість записів на сторінці",
                label2: "Кількість сторінок",
            },

            becameASpecialistButton: {
                button: "Стати фахівцем",
            },

            authTypeSelection: {
                text: "Для подання заявки на участь у проекті як Спеціаліст Вам необхідно зареєструватися або увійти до свого облікового запису.",
                login: "Вхід",
                registration: "Реєстрація",
            },

            specialtySelection: {
                selectCategory: "Виберіть категорію спеціаліста для реєстрації",
                oneCategory:
                    "Ви можете зареєструватися спеціалістом лише однієї категорії",
                medicalSpec: "Медичний спеціаліст",
                medicalSpecText:
                    "Фахівець з вищою медичною освітою та практичним досвідом роботи.",
                translateSpec: "Перекладач",
                translateSpecText:
                    "Наявність освіти перекладача чи лінгвіста, спеціалізація медицини.",
                adminSpec: "Адміністратор консиліуму",
                adminSpecText:
                    "Наявність медичної чи фармацевтичної освіти на рівні не нижче середньої-спеціальної.",
                user: "Продовжити як користувач",
            },

            usePromocode: {
                header: "Використати промокод",
                nameError: "Такого промокоду не існує",
                placeholder: "Промокод",
                activate: "Активувати",
                enterValue: "Введіть промокод",
            },

            errors: {
                standardError: "Виникла помилка, спробуйте пізніше!",
                SERVICE_UNAVAILABLE: "Послуга недоступна",
                NOT_FOUND: 'Не знайдено',
                UNAUTHORIZED: 'Не авторизовано',

                PROMOCODE_NOT_FOUND: "Промокод не знайдено",
                PROMOCODE_IS_NOT_ACTIVE: "Промокод не активний",
                PROMOCODE_IS_NOT_APPLICABLE: "Промокод не застосовується",
                PROMOCODE_LIMIT_ERROR: "Перевищено ліміт використання",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "У зв'язку з використанням промокоду, виведення коштів призупинено",
                PROMOCODE_START_ERROR: "Дія промокоду не розпочалася",
                PROMOCODE_END_ERROR: "Дія промокоду завершилася",
                PROMOCODE_ALREADY_APPLIED_ERROR: "Промокод уже застосований",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "Бонусний промокод уже застосований",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Дату міняти не можна",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Дату міняти не можна",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "Кількість бонусів міняти не можна",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Тип міняти не можна",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "Ліміт використання міняти не можна",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Таке ім'я вже існує",

                USER_SPECIALIST_NOT_FOUND: "Користувач не знайдений",
                USER_SPECIALIST_DELETION_PROHIBITED: "Видалити запит на спеціаліста не дозволено",
                USER_SPECIALIST_HAS_CONCILIUMS: "Видалити запит на спеціаліста не дозволено, користувач є учасником консультації",

                USER_SPECIALIST_DOES_NOT_EXISTS: 'Спеціаліст не існує',
                CAN_NOT_ADD_SPECIALIST: 'Не можна додати спеціаліста',
                ADD_MED_SPECIALIST_FIRST: `Для використання послуг Перекладача необхідно\nзамовити Консультацію/Консиліум медичного фахівця.`,
                TRANSLATOR_ALREADY_ADDED: 'Брати участь у консультації/консиліумі може лише\nодин Перекладач. Ви вже обрали необхідного Перекладача!',
                NEED_MORE_MED_SPECIALISTS: 'Для використання послуг адміністратора консиліуму\nнеобхідно спочатку скликати консиліум. Щоб скликати консиліум, виберіть\nдвох або більше медичних фахівців.',
                ADMINISTRATOR_ALREADY_ADDED: 'Участь у консиліумі може лише один Адміністратор\nконсиліуму. Ви вже обрали необхідного спеціаліста!',
                SPECIALIST_ALREADY_ADDED: 'Спеціаліста уже додано до консиліума/консультації',
                ERROR_ADDING_SPECIALIST: 'Помилка при додаванні спеціаліста',
                MAX_PARTICIPANTS_NUMBER_REACHED: 'Досягнута максимальна кількість учасників',

                CART_IS_EMPTY: 'Спеціалісти не вибрані',
                CART_TRANSLATOR_REQUIRED: 'Не вибраний перекладач',
                CART_ADMINISTRATOR_REQUIRED: 'Не вибраний адміністратор',

                CART_STATUS_IS_NOT_DEFINED: 'Статус консиліуму не визначений',
                CART_CAN_NOT_SET_STATUS: 'Не вдається встановити статус консиліуму',
                CART_PROCESS_IS_NOT_FINISHED: 'Процес створення консиліуму незавершений',
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH: 'Час консультації не співпадає з часом спеціалістів',
                CART_SMALL_TIME_RESERVE: 'До початку консиліуму залишилося мало часу',
                CART_MEETING_TIME_IS_NOT_SET: 'Час консультації не встановлено',

                CONCILIUM_CREATION_ERROR: 'Помилка при створенні консиліуму',

                INSUFFICIENT_BALANCE: "Недостатньо коштів для виведення",
                WRONG_USER_DATA: "Пароль некоректний"
            },

            general: {
                saveBtn: "Зберегти",
                restartBtn: "Перезапустити",
                email: "Email",
                skip: "Пропустити",
                delete: "Видалити",
                confirm: "Підтвердити",
                cancel: "Скасувати",
                information: "Інформація",
                continue: "Продовжити",
                closeBtn: "Закрити"
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Розглядається",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Схвалена",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Відхилена",
                [ConciliumStatus.FINALIZED]: "Завершена",
                [ConciliumStatus.CANCELED]: "Скасована",
                [ConciliumStatus.INJURED]: "Пошкоджена",
            },
            specSettings: {
                cashOutButton: "Зняти кошти",
                cashOutModal: {
                    header: "Запит на зняття коштів",
                    amount: "Сума",
                    note: "Як Вы зможете прийняти кошти?",
                    send: "Направити",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Обов'язкове!",
                        addNotes: "Додайте опис!",
                        greaterThenZero: "Має бути більше за 0!",
                        exact8Characters: "Має бути 8 символів!",
                        exact9Characters: "Має бути 9 символів!",
                        cashOutError: "Виникла помилка, спробуйте пізніше!",
                    },
                    promocode: {
                        header: "Використаний промокод",
                        name: "Промокод",
                        value: "Сума",
                        confirmedError:
                            "У зв'язку з використанням промокоду, виведення коштів призупинено",
                    },
                },
                accountBallance: {
                    current: "Поточний баланс",
                    wallet: "Гаманець",
                },
                cashInButton: "поповнити баланс",
                cashInModal: {
                    header: "Поповнення балансу",
                    add: "Поповнити"
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Деактивація",
                    deactivateAccount: "Деактивувати акаунт",
                    deactivateAccount2: "Деактивувати",
                    deactivateMessage: "Після деактивації Ви не зможете увійти до кабінету та здійснювати діяльність у цьому профілі на порталі Medical Consilium!",
                    deactivateMessage2: "Ви впевнені? Після деактивації Ваш обліковий запис буде видалено. Видалені облікові записи не підлягають відновленню.",
                    passwordPlaceholder: "пароль",
                },
            },
            formFieldsErrors: {
                requiredPassword: "Пароль - це обов'язкове поле",
            },
            specRequest: {
                deactivationConfirmation:
                    "Видалення запиту неможливо скасувати. Всі налаштування запиту будуть втрачені.",
                deleteRequest: "Видалити запит",
                requestCanNotBeDeleted: "Запит не може бути видалений."
            },
            payments: {
                paymentFailedToCreate: "Платіж не створено.",
                paymentFailed: "Виникла помилка при створенні платежу.",
                paymentCreated: "Платіж створено але не завершено. Зверніться до Адміністратора для перевірки стану платежу.",
                paymentWasNotCreated: "Платіж не створено. Спробуйте ще раз."
            },
            specialistSearch: {
                translatorAlreadyAdded: "Ви вже обрали Перекладача!",
                selectAnotherTranslator: "Обрати іншого Перекладача",
                administratorAlreadyAdded: "Ви вже обрали aдміністратора консиліуму!",
                selectAnotherAdministrator: "Вибрати іншого Адміністратора"
            },
            admin: {
                cashOutPage: {
                    minMessageLength: "Повідомлення має містити більше 5 символів",
                }
            },
            formErrors: {
                required: "Обов'язкове!"
            },
            maxSpecialistsModal: {
                edit: "Редагувати"
            }
        },
    },
    en: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Order a consultation",
                createConcilium: "Call a council",
                dateTime: {
                    concilium: "Date and time of the conference",
                    consultation: "Date and time of the consultation",
                    at: "at",
                    edit: "Edit",
                    choiseDateTime: "Select date and time",
                    conciliumLabel: "council",
                    consultationLabel: "consultations",
                    dateTimeText:
                        "The table shows the date and time that is suitable for all participants",
                    duration: "Duration",
                    hour_1: "1 hour",
                    hour_2: "2 hours",
                    hour_3: "3 hours",
                    warning: "You didn't choose the time",
                    selectedRangeIsNotValid: "The selected consultation time is incorrect"
                },
                details: {
                    step_1: "Step 1.",
                    basic: "Main",
                    step_3: "Step 3.",
                    payment: "Payment",
                    proposal: "Application for ",
                    concilium: "council",
                    consultation: "consultation",
                    placeholder: "Describe the reasons for the appeal",
                    addFile: "Attach file",
                    addAudio: "Sound recording",
                    addVideo: "Video recording",
                },
                extra: {
                    text: 'To create a consultation / consultation, you have chosen the time that is billed by specialists as "Urgent"!',
                    next: "Proceed",
                    prev: "Choose another time",
                },
                latest: {
                    concilium: "Council",
                    consultation: "Consultation",
                    text_1: "Your request for a consultation ",
                    text_2: "successfully submitted and will be reviewed shortly.",
                },
                payment: {
                    step_2: "Step 2.",
                    detailsText: "Details",
                    consultationText: "Consultations (Quantity: ",
                    translatorText: "Translation Services (Quantity: ",
                    adminText: "Administrator Services (Quantity: ",
                    allText: "Total",
                    warningText:
                        "You do not have enough funds to order the selected consultation / consultation. To complete (continue) placing an order, please replenish your account in the currency of the site - Medical Concilium Credits.",
                    warningButton: "replenish the balance",
                    confirm:
                        "You confirm your contract, according to which you must make the purchase of the services listed above. You also acknowledge that you have full legal capacity to enter into and perform the contract, and you have read, understand and agree to the Terms of Service and the Privacy Policy of the Medical Concilium website.",
                    next: "Checkout",
                },
                requiredAdmin: {
                    admin: "Council administrator",
                    adminHeader: "Council Administrator Needed!",
                    adminText:
                        "To improve the quality and help in consulting, we suggest using the services of the Council Administrator. In accordance with the Terms of Service and the Privacy Policy of the Medical Concilium, the refusal of the services of the Administrator of the consultation places all obligations for the quality and completeness of its conduct on the initiator of the consultation.",
                    adminButton: "Go to Administrator selection",
                },
                requiredLang: {
                    lang: "Translator",
                    langHeader: "Need a translator!",
                    langText:
                        "You have chosen a specialist for consultation whose communication language does not match yours.",
                    langButton: "Go to Translator selection",
                    specButton: "Choose another Medical Specialist",
                },
                requiredSpec: {
                    specText:
                        "To apply for a Consilium / Consultation, you must select medical specialists.",
                },
                smallTime: {
                    text_1: "Before the start of the consultation, less than",
                    text_2: "minutes, please select another time",
                    cancel: "Cancel",
                    changeTime: "Choose another time",
                },
                specList: {
                    header: "Editing",
                    remove: "Exclude Specialist",
                    add: "Add",
                    spec: "specialist",
                    search: "Continue searching for specialists",
                    next: "Ready",
                },
            },

            promocodesTabHeader: {
                header: "Promocodes",
            },

            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Number of bonuses from",
                    valueEnd: "Number of bonuses up to",
                    createdBegin: "Created from (UTC)",
                    createdEnd: "Created before (UTC)",
                    isActive: {
                        all: "Everything",
                        yes: "Active",
                        no: "Inactive",
                    },
                    reset: "Reset",
                    filter: "Filter",
                    ready: "Ready",
                },
                promocodeAddModal: {
                    header: "Create promocode",
                    changePromocodeHeader: "Change promocode",
                    categoryHeader: "Available to categories",
                    changeName: "Change",
                    submit: "Send",
                    validation: {
                        required: "Required field",
                        integer: "Only integer value",
                        min1: "The minimum value - 0.01",
                        min2: "The minimum value - 0",
                        existingName: "Such a name already exists",
                        namePattern:
                            "Only uppercase Latin letters and numbers 0-9 (10 characters)",
                        endDate: "End time cannot be before start time",
                        exact8Characters: "Maximum 8-digit number",
                        number: "This must be a number",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Create a promocode",
                    buttonChangeName: "Change",
                },
                promocodeFields: {
                    name: "Name",
                    type: "Type",
                    startDate: "Begin of action",
                    endDate: "End of action",
                    startDateUTCFrom: "Begin of action (UTC) from",
                    endDateUTCFrom: "End of action (UTC) from",
                    startDateUTCBefore: "Begin of action (UTC) before",
                    endDateUTCBefore: "End of action (UTC) before",
                    value: "Quantity of bonuses",
                    isActive: {
                        isActiveHeader: "Active",
                        yes: "yes",
                        no: "no",
                    },
                    userTypes: {
                        userTypesHeader: "User types",
                        MedicalSpecialist: "MedicalSpecialist",
                        Translator: "Translator",
                        Administrator: "Administrator",
                    },
                    usagesLimit: "Usages Limit",
                    created: "Created (UTC)",
                    updated: "Updated (UTC)",
                    usages: "Usages",
                    actions: "Actions",
                },
            },
            promocodesPagination: {
                label: "Quantity of records per page",
                label2: "Number of pages",
            },

            becameASpecialistButton: {
                button: "Became a specialist",
            },

            authTypeSelection: {
                text: "To apply for the project as a Specialist, you need to register or log in to your account.",
                login: "Entry",
                registration: "Registration",
            },

            specialtySelection: {
                selectCategory: "Select a specialist category to register",
                oneCategory:
                    "You can only register as a specialist in one category",
                medicalSpec: "Medical specialist",
                medicalSpecText:
                    "Specialist with higher medical education and practical experience.",
                translateSpec: "Translator",
                translateSpecText:
                    "Education of a translator or linguist, specialization in medicine.",
                adminSpec: "Council administrator",
                adminSpecText:
                    "Presence of medical or pharmaceutical education at a level not lower than secondary special education.",
                user: "Continue as user",
            },

            usePromocode: {
                header: "Use a promocode",
                nameError: "There is no such promocode",
                placeholder: "Promocode",
                activate: "Activate",
                enterValue: "Enter the promo code",
            },

            errors: {
                standardError: "An error occurred, please try again later!",
                SERVICE_UNAVAILABLE: "Service unavailable",
                NOT_FOUND: 'Not found',
                UNAUTHORIZED: 'Not authorized',

                PROMOCODE_NOT_FOUND: "Promocode not found",
                PROMOCODE_IS_NOT_ACTIVE: "The promocode is not active",
                PROMOCODE_IS_NOT_APPLICABLE: "Promocode does not apply",
                PROMOCODE_LIMIT_ERROR: "Usage limit exceeded",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "Due to the use of the promotional code, the withdrawal of funds is suspended",
                PROMOCODE_START_ERROR: "The promocode has not started",
                PROMOCODE_END_ERROR: "The promocode has expired",
                PROMOCODE_ALREADY_APPLIED_ERROR:
                    "The promocode has already been applied",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "The bonus promocode has already been applied",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Date cannot be changed",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Date cannot be changed",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "The value of bonuses cannot be changed",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Type cannot be changed",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "The usage limit cannot be changed",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Such a name already exists",

                USER_SPECIALIST_NOT_FOUND: "User not found",
                USER_SPECIALIST_DELETION_PROHIBITED: "It is not allowed to delete a request for a specialist",
                USER_SPECIALIST_HAS_CONCILIUMS: "It is not allowed to delete a request for a specialist, the user is a participant in the consultation",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Specialist does not exists",
                CAN_NOT_ADD_SPECIALIST: 'You cannot add a specialist',
                ADD_MED_SPECIALIST_FIRST: "To use the services of an Interpreter, you must first\norder a Consultation/Consilium of a medical specialist.",
                TRANSLATOR_ALREADY_ADDED: "Only one Translator can participate in the consultation/concilium.\nYou have already chosen the required Translator!",
                NEED_MORE_MED_SPECIALISTS: "To use the services of the Council Administrator, you must first\nconvene a council. To convene a council, select two\nor more Medical Specialists.",
                ADMINISTRATOR_ALREADY_ADDED: "Only one Council Administrator can participate in the council.\nYou have already chosen the necessary specialist!",
                SPECIALIST_ALREADY_ADDED: "The specialist has already been added to the consultation/concilium.",
                ERROR_ADDING_SPECIALIST: 'Error adding specialist',
                MAX_PARTICIPANTS_NUMBER_REACHED: 'The maximum number of participants has been reached',

                CART_IS_EMPTY: 'Specialists are not selected',
                CART_TRANSLATOR_REQUIRED: 'No translator selected',
                CART_ADMINISTRATOR_REQUIRED: 'No administrator selected',

                CART_STATUS_IS_NOT_DEFINED: 'The status of the council is not defined',
                CART_CAN_NOT_SET_STATUS: 'Unable to establish consilium status',
                CART_PROCESS_IS_NOT_FINISHED: 'The process of creating a consilium is incomplete',
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH: 'Consultation time does not coincide with specialist time',
                CART_SMALL_TIME_RESERVE: 'There is little time left before the start of the conference',
                CART_MEETING_TIME_IS_NOT_SET: 'The consultation time has not been set',

                CONCILIUM_CREATION_ERROR: 'An error occurred when creating a consilium',

                INSUFFICIENT_BALANCE: "Insufficient funds to withdraw",
                WRONG_USER_DATA: "The password is incorrect"
            },

            general: {
                saveBtn: "Save",
                restartBtn: "Restart",
                email: "Email",
                skip: "Skip",
                delete: "Delete",
                confirm: "Confirm",
                cancel: "Cancel",
                information: "Information",
                continue: "Continue",
                closeBtn: "Close"
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Initiated",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Approved",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Refused",
                [ConciliumStatus.FINALIZED]: "Finalized",
                [ConciliumStatus.CANCELED]: "Canceled",
                [ConciliumStatus.INJURED]: "Injured",
            },
            specSettings: {
                cashOutButton: "Withdraw funds",
                cashOutModal: {
                    header: "Withdrawal application",
                    amount: "Amount",
                    note: "How can you receive funds?",
                    send: "Send",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Required!",
                        addNotes: "Please add notes!",
                        greaterThenZero: "Should be more then 0!",
                        exact8Characters: "Must be exactly 8 characters",
                        exact9Characters: "Must be exactly 9 characters",
                        cashOutError:
                            "An error occurred, please try again later!",
                    },
                    promocode: {
                        header: "Promocode used",
                        name: "Promocode",
                        value: "Sum",
                        confirmedError:
                            "Due to the use of the promotional code, the withdrawal of funds is suspended",
                    },
                },
                accountBallance: {
                    current: "Current balance",
                    wallet: "Wallet",
                },
                cashInButton: "Top up the balance",
                cashInModal: {
                    header: "Top up your balance",
                    add: "Add funds",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Deactivation",
                    deactivateAccount: "Deactivate account",
                    deactivateAccount2: "Deactivate",
                    deactivateMessage: "After deactivation, you will not be able to enter the office and carry out activities in this profile on the Medical Consilium portal!",
                    deactivateMessage2: "Are you sure? After deactivation, your account will be deleted. Deleted accounts cannot be restored.",
                    passwordPlaceholder: "password",
                },
            },
            formFieldsErrors: {
                requiredPassword: "Password is a required field",
            },
            specRequest: {
                deactivationConfirmation:
                    "Unable to delete request. All request settings will be lost.",
                deleteRequest: "Delete request",
                requestCanNotBeDeleted: "The request cannot be deleted."
            },
            payments: {
                paymentFailedToCreate: "Payment was not created.",
                paymentFailed: "There was an error creating the payment.",
                paymentCreated: "Payment created but not completed. Contact the Administrator to check the status of the payment.",
                paymentWasNotCreated: "Payment was not created. Please try later."
            },
            specialistSearch: {
                translatorAlreadyAdded: "You have already selected a Translator!",
                selectAnotherTranslator: "Choose another Translator",
                administratorAlreadyAdded: "You have already selected a Сoncilium Administrator!",
                selectAnotherAdministrator: "Choose another Administrator"
            },
            admin: {
                cashOutPage: {
                    minMessageLength: "The message must contain more than 5 characters",
                }
            },
            formErrors: {
                required: "Required!"
            },
            maxSpecialistsModal: {
                edit: "Edit"
            }
        }
    },
    ru: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Заказать консультацию",
                createConcilium: "Созвать консилиум",
                dateTime: {
                    concilium: "Дата и время проведения консилиума",
                    consultation: "Дата и время проведения консультации",
                    at: "в",
                    edit: "Редактировать",
                    choiseDateTime: "Выберите дату и время",
                    conciliumLabel: "консилиума",
                    consultationLabel: "консультации",
                    dateTimeText:
                        "В таблице отображается дата и время, которое подходит для всех участников",
                    duration: "Длительность",
                    hour_1: "1 час",
                    hour_2: "2 часа",
                    hour_3: "3 часа",
                    warning: "Вы не выбрали время",
                    selectedRangeIsNotValid: "Выбранное время консультации некорректно"
                },
                details: {
                    step_1: "Шаг 1.",
                    basic: "Основное",
                    step_3: "Шаг 3.",
                    payment: "Оплата",
                    proposal: "Заявка на ",
                    concilium: "консилиум",
                    consultation: "консультацию",
                    placeholder: "Опишите причины обращения",
                    addFile: "Прикрепить файл",
                    addAudio: "Запись звука",
                    addVideo: "Запись видео",
                },
                extra: {
                    text: 'Для создания консультации/консилиума Вы выбрали время, которое тарифицируется специалистами как "Срочная"!',
                    next: "Продолжить",
                    prev: "Выбрать другое время",
                },
                latest: {
                    concilium: "Консилиум",
                    consultation: "Консультация",
                    text_1: "Ваша заявка на консультацию от ",
                    text_2: "года успешно направлена, и будет рассмотрена в ближайшее время.",
                },
                payment: {
                    step_2: "Шаг 2.",
                    detailsText: "Детали",
                    consultationText: "Консультации (Количество: ",
                    translatorText: "Услуги переводчика (Количество: ",
                    adminText: "Услуги администратора (Количество: ",
                    allText: "Всего",
                    warningText:
                        "У Вас недостаточно денежных средств, чтобы  заказать выбранную консультацию/консилиум. Для завершения (продолжения) оформления заказа просим пополнить свой cчет в валюте сайта - Medical Concilium Credits.",
                    warningButton: "пополнить баланс",
                    confirm:
                        "Вы подтверждаете свой контракт, согласно которому Вы должны осуществить покупку услуг, перечисленных выше. Вы также подтверждаете, что Вы обладаете полной право- и дееспособностью для заключения и исполнения контракта, и Вы прочитали, понимаете и согласны с Условиями предоставления услуг и с Политикой конфиденциальности сайта Medical Concilium.",
                    next: "Оформить заказ",
                },
                requiredAdmin: {
                    admin: "Администратор консилиума",
                    adminHeader: "Необходим Администратор консилиума!",
                    adminText:
                        "Для улучшения качества и помощи в консультировании, предлагаем воспользоваться услугами Администратора консилиума. В соответствии с Условиями предоставления услуг и Политикой конфиденциальности Medical Concilium, отказ от услуг Администратора консилиума возлагает все обязательства по качеству и полноте его проведения на инициатора консилиума.",
                    adminButton: "Перейти к выбору Администратора",
                },
                requiredLang: {
                    lang: "Переводчик",
                    langHeader: "Необходим Переводчик!",
                    langText:
                        "Вы выбрали для консультации специалиста, язык общения которого не совпадает с Вашим.",
                    langButton: "Перейти к выбору Переводчика",
                    specButton: "Выбрать другого Медицинского специалиста",
                },
                requiredSpec: {
                    specText:
                        "Для оформления заявки на Консилиум/Консультацию - необходимо выбрать медицинских специалистов.",
                },
                smallTime: {
                    text_1: "До начала консилиума осталось менее",
                    text_2: "минут, пожалуйста, выберите другое время",
                    cancel: "Отменить",
                    changeTime: "Выбрать другое время",
                },
                specList: {
                    header: "Редактирование",
                    remove: "Исключить специалиста",
                    add: "Добавить",
                    spec: "специалиста",
                    search: "Продолжить поиск специалистов",
                    next: "Готово",
                },
            },

            promocodesTabHeader: {
                header: "Промокоды",
            },

            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Количество бонусов от",
                    valueEnd: "Количество бонусов до",
                    createdBegin: "Создан от (UTC)",
                    createdEnd: "Создан до (UTC)",
                    isActive: {
                        all: "Все",
                        yes: "Активные",
                        no: "Неактивные",
                    },
                    reset: "Сбросить",
                    filter: "Отфильтровать",
                    ready: "Готово",
                },
                promocodeAddModal: {
                    header: "Создать промокод",
                    changePromocodeHeader: "Изменить промокод",
                    categoryHeader: "Доступный категориям",
                    changeName: "Изменить",
                    submit: "Отправить",
                    validation: {
                        required: "Обязательное поле",
                        integer: "Только целое значение",
                        min1: "Минимальное значение - 0,01",
                        min2: "Минимальное значение - 0",
                        existingName: "Такое имя уже существует",
                        namePattern:
                            "Только латиница верхнего регистра и цифры 0-9 (10 символов)",
                        endDate:
                            "Время окончания не может быть раньше времени начала",
                        exact8Characters: "Максимум 8-значное число",
                        number: "Это должно быть число",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Создать промокод",
                    buttonChangeName: "Изменить",
                },
                promocodeFields: {
                    name: "Название",
                    type: "Тип",
                    startDate: "Начало действия",
                    endDate: "Конец действия",
                    startDateUTCFrom: "Начало действия (UTC) от",
                    endDateUTCFrom: "Конец действия (UTC) от",
                    startDateUTCBefore: "Начало действия (UTC) до",
                    endDateUTCBefore: "Конец действия (UTC) до",
                    value: "Количество бонусов",
                    isActive: {
                        isActiveHeader: "Активный",
                        yes: "да",
                        no: "нет",
                    },
                    userTypes: {
                        userTypesHeader: "Типы пользователей",
                        MedicalSpecialist: "Медицинский специалист",
                        Translator: "Переводчик",
                        Administrator: "Администратор",
                    },
                    usagesLimit: "Лимит использования",
                    created: "Создан (UTC)",
                    updated: "Обновлен (UTC)",
                    usages: "Использован",
                    actions: "Действия",
                },
            },
            promocodesPagination: {
                label: "Количество записей на странице",
                label2: "Количество страниц",
            },

            becameASpecialistButton: {
                button: "Стать специалистом",
            },

            authTypeSelection: {
                text: "Чтобы подать заявку на участие в проекте в качестве специалиста, вам необходимо зарегистрироваться или войти в свою учетную запись.",
                login: "Вход",
                registration: "Регистрация",
            },

            specialtySelection: {
                selectCategory:
                    "Выберите категорию специалистов для регистрации",
                oneCategory:
                    "Вы можете зарегистрироваться как специалист только в одной категории",
                medicalSpec: "Медицинский специалист",
                medicalSpecText:
                    "Специалист с высшим медицинским образованием и практическим опытом.",
                translateSpec: "Переводчик",
                translateSpecText:
                    "Образование переводчика или лингвиста, специальность - медицина.",
                adminSpec: "Администратор консилиума",
                adminSpecText:
                    "Наличие медицинского или фармацевтического образования на уровне не ниже среднего специального образования.",
                user: "Продолжить как пользователь",
            },

            usePromocode: {
                header: "Используйте промокод",
                nameError: "Такого промокода не существует",
                placeholder: "Промокод",
                activate: "Активировать",
                enterValue: "Введите промокод",
            },

            errors: {
                standardError: "Возникла ошибка, попробуйте позже!",
                SERVICE_UNAVAILABLE: "Услуга недоступна",
                NOT_FOUND: 'Не найдено',
                UNAUTHORIZED: 'Не авторизовано',

                PROMOCODE_NOT_FOUND: "Промокод не найден",
                PROMOCODE_IS_NOT_ACTIVE: "Промокод не активен",
                PROMOCODE_IS_NOT_APPLICABLE: "Промокод не действителен",
                PROMOCODE_LIMIT_ERROR: "Превышен лимит использования",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "В связи с использованием промокода, вывод средств приостановлен",
                PROMOCODE_START_ERROR: "Действие промокода не началось",
                PROMOCODE_END_ERROR: "Действие промокода завершилось",
                PROMOCODE_ALREADY_APPLIED_ERROR: "Промокод уже применен",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "Бонусный промокод уже применен",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Дату менять нельзя",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Дату менять нельзя",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "Количество бонусов менять нельзя",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Тип менять нельзя",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "Лимит использования менять нельзя",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Такое имя уже существует",

                USER_SPECIALIST_NOT_FOUND: "Пользователь не найден",
                USER_SPECIALIST_DELETION_PROHIBITED: "Удалить запрос на специалиста не разрешено",
                USER_SPECIALIST_HAS_CONCILIUMS: "Удалить запрос на специалиста не разрешено, пользователь является участником консультации",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Специалист не существует",
                CAN_NOT_ADD_SPECIALIST: 'Нельзя добавить специалиста',
                ADD_MED_SPECIALIST_FIRST: "Для использования услуг Переводчика необходимо сначала заказать\nКонсультацию/Консилиум медицинского специалиста.",
                TRANSLATOR_ALREADY_ADDED: "Участвовать в консультации/консилиуме может только один\nПереводчик. Вы уже выбрали необходимого Переводчика!",
                NEED_MORE_MED_SPECIALISTS: "Для использования услуг Администратора консилиума\nнеобходимо сначала созвать консилиум. Чтобы созвать консилиум\nвыберите двух или более Медицинских специалистов.",
                ADMINISTRATOR_ALREADY_ADDED: "Участвовать в консилиуме может только один Администратор\nконсилиума. Вы уже выбрали необходимого специалиста!",
                SPECIALIST_ALREADY_ADDED: "Специалист уже добавлен в консилиум/консультацию.",
                ERROR_ADDING_SPECIALIST: 'Ошибка при добавлении специалиста',
                MAX_PARTICIPANTS_NUMBER_REACHED: 'Достигнуто максимальное количество участников',

                CART_IS_EMPTY: 'Специалисты не выбраны',
                CART_TRANSLATOR_REQUIRED: 'Не выбран переводчик',
                CART_ADMINISTRATOR_REQUIRED: 'Не выбранный администратор',

                CART_STATUS_IS_NOT_DEFINED: 'Статус консилиума не определен',
                CART_CAN_NOT_SET_STATUS: 'Не удается установить статус консилиума',
                CART_PROCESS_IS_NOT_FINISHED: 'Процесс создания консилиума не завершен',
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH: 'Время консультации не совпадает со временем специалистов',
                CART_SMALL_TIME_RESERVE: 'До начала консилиума осталось мало времени',
                CART_MEETING_TIME_IS_NOT_SET: 'Время консультации не установлено',

                CONCILIUM_CREATION_ERROR: 'Ошибка при создании консилиума',

                INSUFFICIENT_BALANCE: "Недостаточно средств для вывода",
                WRONG_USER_DATA: "Пароль некорректен"
            },

            general: {
                saveBtn: "Сохранить",
                restartBtn: "Перезапустить",
                email: "Email",
                skip: "Пропустить",
                delete: "Удалить",
                confirm: "Подтвердить",
                cancel: "Отклонить",
                information: "Информация",
                continue: "Продолжить",
                closeBtn: "Закрыть"
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Рассматривается",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Одобрена",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Отклонена",
                [ConciliumStatus.FINALIZED]: "Завершена",
                [ConciliumStatus.CANCELED]: "Отменена",
                [ConciliumStatus.INJURED]: "Повреждена",
            },
            specSettings: {
                cashOutButton: "Снять средства",
                cashOutModal: {
                    header: "Заявка на снятие средств",
                    amount: "Сумма",
                    note: "Как вы сможете принять средства?",
                    send: "Отправить",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Обязательное!",
                        addNotes: "Добавьте описание!",
                        greaterThenZero: "Должно быть больше 0!",
                        exact8Characters: "Должно быть 8 символов!",
                        exact9Characters: "Должно быть 9 символов!",
                        cashOutError: "Возникла ошибка, попробуйте позже!",
                    },
                    promocode: {
                        header: "Использованный промокод",
                        name: "Промокод",
                        value: "Сумма",
                        confirmedError:
                            "В связи с использованием промокода, вывод средств приостановлен",
                    },
                },
                accountBallance: {
                    current: "Текущий баланс",
                    wallet: "Кошелёк",
                },
                cashInButton: "пополнить баланс",
                cashInModal: {
                    header: "Пополнение баланса",
                    add: "Пополнить",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Деактивация",
                    deactivateAccount: "Деактивировать аккаунт",
                    deactivateAccount2: "Деактивировать",
                    deactivateMessage: "После деактивации Вы не сможете войти в кабинет и осуществлять деятельность в этом профиле на портале Medical Consilium!",
                    deactivateMessage2: "Вы уверены? После деактивации Ваш аккаунт будет удален. Удаленные аккаунты не подлежат восстановлению.",
                    passwordPlaceholder: "пароль",
                },
            },
            formFieldsErrors: {
                requiredPassword: "Пароль – это обязательное поле",
            },
            specRequest: {
                deactivationConfirmation:
                    "Удаление запроса невозможно отменить. Все настройки запроса будут потеряны.",
                deleteRequest: "Удалить запрос",
                requestCanNotBeDeleted: "Запрос не может быть удалён."
            },
            payments: {
                paymentFailedToCreate: "Платеж не создан.",
                paymentFailed: "Возникла ошибка при создании платежа.",
                paymentCreated: "Платеж создан, но не завершен. Обратитесь к Администратору для проверки состояния платежа.",
                paymentWasNotCreated: "Платеж не создан. Попытайтесь еще раз."
            },
            specialistSearch: {
                translatorAlreadyAdded: "Вы уже выбрали Переводчика!",
                selectAnotherTranslator: "Выбрать другого Переводчика",
                administratorAlreadyAdded: "Вы уже выбрали администратора консилиума!",
                selectAnotherAdministrator: "Выбрать другого Администратора"
            },
            admin: {
                cashOutPage: {
                    minMessageLength: "Сообщение должно содержать более 5 символов",
                }
            },
            formErrors: {
                required: "Обязательное!"
            },
            maxSpecialistsModal: {
                edit: "Редактировать"
            }
        }
    },
    pl: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Zamów konsultację",
                createConcilium: "Zadzwoń do rady",
                dateTime: {
                    concilium: "Data i godzina konferencji",
                    consultation: "Data i godzina konsultacji",
                    at: "o",
                    edit: "Edytować",
                    choiseDateTime: "Wybierz datę i godzinę",
                    conciliumLabel: "rada",
                    consultationLabel: "konsultacje",
                    dateTimeText:
                        "Tabela wyświetla datę i godzinę odpowiednią dla wszystkich uczestników",
                    duration: "Czas trwania",
                    hour_1: "1 godzina",
                    hour_2: "2 godziny",
                    hour_3: "3 godziny",
                    warning: "Nie wybrałeś czasu",
                    selectedRangeIsNotValid: "Wybrany czas konsultacji jest nieprawidłowy"
                },
                details: {
                    step_1: "Krok 1.",
                    basic: "Główny",
                    step_3: "Krok 3.",
                    payment: "Zapłata",
                    proposal: "Aplikacja do ",
                    concilium: "rada",
                    consultation: "konsultacja",
                    placeholder: "Opisz powody odwołania",
                    addFile: "Załącz plik",
                    addAudio: "Nagrywanie dźwięku",
                    addVideo: "Nagrywanie wideo",
                },
                extra: {
                    text: 'Aby utworzyć konsultację/konsultację wybrałeś czas, który jest rozliczany przez specjalistów jako "Pilny"!',
                    next: "Kontynuować",
                    prev: "Wybierz inny czas",
                },
                latest: {
                    concilium: "Rada",
                    consultation: "Konsultacja",
                    text_1: "Twoja prośba o konsultację ",
                    text_2: "pomyślnie przesłane i wkrótce zostaną sprawdzone.",
                },
                payment: {
                    step_2: "Krok 2.",
                    detailsText: "Detale",
                    consultationText: "Konsultacje (Ilość: ",
                    translatorText: "Usługi tłumaczeniowe (ilość: ",
                    adminText: "Usługi administratora (ilość: ",
                    allText: "Całkowity",
                    warningText:
                        "Nie masz wystarczających środków, aby zamówić wybraną konsultację/konsultację. Aby dokończyć (kontynuować) składanie zamówienia, prosimy o doładowanie konta w walucie serwisu - Medical Concilium Credits.",
                    warningButton: "uzupełnij saldo",
                    confirm:
                        "Potwierdzasz umowę, zgodnie z którą musisz dokonać zakupu usług wymienionych powyżej. Przyjmujesz również do wiadomości, że masz pełną zdolność do czynności prawnych do zawarcia i wykonania umowy oraz przeczytałeś, zrozumiałeś i zgadzasz się z Warunkami świadczenia usług i Polityką prywatności witryny internetowej Medical Concilium.",
                    next: "Sprawdzić",
                },
                requiredAdmin: {
                    admin: "Administrator Rady",
                    adminHeader: "Potrzebny administrator Rady!",
                    adminText:
                        "W celu podniesienia jakości i pomocy w doradztwie proponujemy skorzystanie z usług Administratora Rady. Zgodnie z Regulaminem oraz Polityką Prywatności Konsylium Medycznego odmowa świadczenia usług przez Administratora konsultacji nakłada na inicjatora konsultacji wszelkie zobowiązania dotyczące jakości i kompletności jej przeprowadzenia.",
                    adminButton: "Przejdź do wyboru administratora",
                },
                requiredLang: {
                    lang: "Tłumacz",
                    langHeader: "Potrzebujesz tłumacza!",
                    langText:
                        "Wybrałeś do konsultacji specjalistę, którego język komunikacji nie pasuje do Twojego.",
                    langButton: "Przejdź do wyboru tłumacza",
                    specButton: "Wybierz innego specjalistę medycznego",
                },
                requiredSpec: {
                    specText:
                        "Aby ubiegać się o Consilium / Konsultację, musisz wybrać lekarzy specjalistów.",
                },
                smallTime: {
                    text_1: "Przed rozpoczęciem konsultacji mniej niż",
                    text_2: "minut, proszę wybrać inny czas",
                    cancel: "Anulować",
                    changeTime: "Wybierz inny czas",
                },
                specList: {
                    header: "Redagowanie",
                    remove: "Wyklucz specjalistę",
                    add: "Dodać",
                    spec: "specjalista",
                    search: "Kontynuuj poszukiwanie specjalistów",
                    next: "Gotowy",
                },
            },

            promocodesTabHeader: {
                header: "Kody promocyjne",
            },

            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Liczba bonusów od",
                    valueEnd: "Liczba bonusów do",
                    createdBegin: "Utworzono z (UTC)",
                    createdEnd: "Utworzono wcześniej (UTC)",
                    isActive: {
                        all: "Wszystko",
                        yes: "Aktywny",
                        no: "Nieaktywny",
                    },
                    reset: "Resetowanie",
                    filter: "Filtr",
                    ready: "Gotowe",
                },
                promocodeAddModal: {
                    header: "Utwórz kod promocyjny",
                    changePromocodeHeader: "Zmień kod promocyjny",
                    categoryHeader: "Dostępne dla kategorii",
                    changeName: "Reszta",
                    submit: "Wysłać",
                    validation: {
                        required: "Pole wymagane",
                        integer: "Tylko liczba całkowita",
                        min1: "Minimalna wartość - 0,01",
                        min2: "Minimalna wartość - 0",
                        existingName: "Taka nazwa już istnieje",
                        namePattern:
                            "Tylko łacina wielkimi literami i cyfry 0-9 (10 znaków)",
                        endDate:
                            "Czas zakończenia nie może być wcześniejszy niż czas rozpoczęcia",
                        exact8Characters: "Maksymalna liczba 8-cyfrowa",
                        number: "To musi być liczba",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Utwórz kod promocyjny",
                    buttonChangeName: "Zmiana",
                },
                promocodeFields: {
                    name: "Nazwa",
                    type: "Rodzaj",
                    startDate: "Początek akcji",
                    endDate: "Koniec akcji",
                    startDateUTCFrom: "Początek akcji (UTC) z",
                    endDateUTCFrom: "Koniec akcji (UTC) z",
                    startDateUTCBefore: "Początek akcji (UTC) zanim",
                    endDateUTCBefore: "Koniec akcji (UTC) zanim",
                    value: "Liczba bonusów",
                    isActive: {
                        isActiveHeader: "Aktywny",
                        yes: "tak",
                        no: "nie",
                    },
                    userTypes: {
                        userTypesHeader: "Typy użytkowników",
                        MedicalSpecialist: "Specjalista medyczny",
                        Translator: "Tłumacz",
                        Administrator: "Administrator",
                    },
                    usagesLimit: "Limit użytkowania",
                    created: "Wygenerowane (UTC)",
                    updated: "Odnowiony (UTC)",
                    usages: "Używany",
                    actions: "Działania",
                },
            },
            promocodesPagination: {
                label: "Liczba rekordów na stronie",
                label2: "Numer stron",
            },

            becameASpecialistButton: {
                button: "Zostań specjalistą",
            },

            authTypeSelection: {
                text: "Aby zgłosić się do udziału w projekcie jako specjalista, musisz się zarejestrować lub zalogować na swoje konto.",
                login: "Wejście",
                registration: "Rejestracja",
            },

            specialtySelection: {
                selectCategory: "Wybierz kategorię specjalistów do rejestracji",
                oneCategory:
                    "Możesz zarejestrować się jako specjalista tylko w jednej kategorii",
                medicalSpec: "Specjalista medyczny",
                medicalSpecText:
                    "Specjalista z wyższym wykształceniem medycznym i praktycznym doświadczeniem.",
                translateSpec: "Tłumacz",
                translateSpecText:
                    "Wykształcenie tłumacza lub językoznawcy, specjalność - medycyna.",
                adminSpec: "Administrator Rady",
                adminSpecText:
                    "Dostępność wykształcenia medycznego lub farmaceutycznego na poziomie co najmniej średniego wykształcenia kierunkowego.",
                user: "Kontynuuj jako użytkownik",
            },

            usePromocode: {
                header: "Użyj kodu promocyjnego",
                nameError: "Nie ma takiego kodu promocyjnego.",
                placeholder: "Kod promocyjny",
                activate: "Aktywuj",
                enterValue: "Wpisz kod promocyjny",
            },

            errors: {
                standardError: "Wystąpił błąd. Proszę spróbować później!",
                SERVICE_UNAVAILABLE: "Serwis niedostępny",
                NOT_FOUND: 'Nie znaleziono',
                UNAUTHORIZED: 'Nieautoryzowany',

                PROMOCODE_NOT_FOUND: "Nie znaleziono kodu promocyjnego",
                PROMOCODE_IS_NOT_ACTIVE: "Kod promocyjny nie jest aktywny",
                PROMOCODE_IS_NOT_APPLICABLE:
                    "Kod promocyjny jest nieprawidłowy",
                PROMOCODE_LIMIT_ERROR: "Przekroczono limit użytkowania",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "Ze względu na użycie kodu promocyjnego wypłata środków jest zawieszona",
                PROMOCODE_START_ERROR: "Kod promocyjny nie został uruchomiony",
                PROMOCODE_END_ERROR: "Kod promocyjny wygasł",
                PROMOCODE_ALREADY_APPLIED_ERROR:
                    "Kod promocyjny został już zastosowany",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "Bonusowy kod promocyjny został już zastosowany",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Nie można zmienić daty",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Nie można zmienić daty",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "Liczby bonusów nie można zmienić",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Nie można zmienić typu",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "Limitu użytkowania nie można zmienić",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Taka nazwa już istnieje",

                USER_SPECIALIST_NOT_FOUND: "Nie znaleziono użytkownika",
                USER_SPECIALIST_DELETION_PROHIBITED: "Nie można usunąć prośby o specjalistę",
                USER_SPECIALIST_HAS_CONCILIUMS: "Niedozwolone jest usunięcie wniosku o specjalistę, użytkownik jest uczestnikiem konsultacji",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Nie ma specjalisty",
                CAN_NOT_ADD_SPECIALIST: 'Nie możesz dodać specjalisty',
                ADD_MED_SPECIALIST_FIRST: "Aby skorzystać z usług tłumacza, należy najpierw\nzamówić Konsultację/Consilium lekarza specjalisty.",
                TRANSLATOR_ALREADY_ADDED: "W konsultacjach/konsylium może brać udział tylko\njeden Tłumacz. Wybrałeś już wymaganego Tłumacza!",
                NEED_MORE_MED_SPECIALISTS: "Aby skorzystać z usług Administratora Rady należy\nnajpierw zwołać Radę. Aby zwołać radę, wybierz dwóch\nlub więcej specjalistów medycznych.",
                ADMINISTRATOR_ALREADY_ADDED: "W radzie może uczestniczyć tylko jeden Administrator\nRady. Wybrałeś już potrzebnego specjalistę!",
                SPECIALIST_ALREADY_ADDED: "Specjalista został już dodany do konsultacji/konsultacji.",
                ERROR_ADDING_SPECIALIST: 'Błąd podczas dodawania specjalisty',
                MAX_PARTICIPANTS_NUMBER_REACHED: 'Osiągnięto maksymalną liczbę uczestników',

                CART_IS_EMPTY: 'Specjaliści nie są wybierani',
                CART_TRANSLATOR_REQUIRED: 'Nie wybrano tłumacza',
                CART_ADMINISTRATOR_REQUIRED: 'Nie wybrano administratora',

                CART_STATUS_IS_NOT_DEFINED: 'Status rady nie jest określony',
                CART_CAN_NOT_SET_STATUS: 'Nie można ustalić statusu consilium',
                CART_PROCESS_IS_NOT_FINISHED: 'Proces tworzenia consilium jest niekompletny',
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH: 'Czas konsultacji nie pokrywa się z czasem specjalisty',
                CART_SMALL_TIME_RESERVE: 'Do rozpoczęcia konferencji pozostało niewiele czasu',
                CART_MEETING_TIME_IS_NOT_SET: 'Nie ustawiono czasu konsultacji',

                CONCILIUM_CREATION_ERROR: 'Wystąpił błąd podczas tworzenia consilium',

                INSUFFICIENT_BALANCE: "Niewystarczające środki do wypłaty",
                WRONG_USER_DATA: "Hasło jest błędne"
            },

            general: {
                saveBtn: "Zapisać",
                restartBtn: "Uruchom ponownie",
                email: "Email",
                skip: "Chybić",
                delete: "Usunąć",
                confirm: "Potwierdzać",
                cancel: "Anuluj",
                information: "Informacja",
                continue: "Kontynuuj",
                closeBtn: "Zamknąć"
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Uważany za",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Zatwierdzony",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Odrzucony",
                [ConciliumStatus.FINALIZED]: "Zakończony",
                [ConciliumStatus.CANCELED]: "Odwołany",
                [ConciliumStatus.INJURED]: "Ranny",
            },
            specSettings: {
                cashOutButton: "Wypłata środków",
                cashOutModal: {
                    header: "Prośba o wycofanie",
                    amount: "Suma",
                    note: "Jak możesz przyjmować środki?",
                    send: "Wysłać",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Wymagany!",
                        addNotes: "Dodaj opis!",
                        greaterThenZero: "Musi być powyżej 0!",
                        exact8Characters: "Musi mieć 8 znaków!",
                        exact9Characters: "Musi mieć 9 znaków!",
                        cashOutError:
                            "Wystąpił błąd. Proszę spróbować później!",
                    },
                    promocode: {
                        header: "Użyty kod promocyjny",
                        name: "Kod promocyjny",
                        value: "Suma",
                        confirmedError:
                            "Ze względu na użycie kodu promocyjnego wypłata środków jest zawieszona",
                    },
                },
                accountBallance: {
                    current: "Aktualne saldo",
                    wallet: "Portmonetka",
                },
                cashInButton: "doładuj saldo",
                cashInModal: {
                    header: "Uzupełnianie salda",
                    add: "Uzupełniać",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Dezaktywacja",
                    deactivateAccount: "Dezaktywuj konto",
                    deactivateAccount2: "Dezaktywować",
                    deactivateMessage: "Po dezaktywacji nie będziesz mógł wejść do urzędu i prowadzić czynności w tym profilu na portalu Medical Consilium!",
                    deactivateMessage2: "Jesteś pewny? Po dezaktywacji Twoje konto zostanie usunięte. Usuniętych kont nie można przywrócić.",
                    passwordPlaceholder: "hasło",
                },
            },
            formFieldsErrors: {
                requiredPassword: "Hasło jest polem wymaganym",
            },
            specRequest: {
                deactivationConfirmation:
                    "Nie udało się usunąć prośby. Wszystkie ustawienia zapytania zostaną utracone.",
                deleteRequest: "Usuń prośbę",
                requestCanNotBeDeleted: "Żądanie nie może zostać usunięte."
            },
            payments: {
                paymentFailedToCreate: "Płatność nie została utworzona.",
                paymentFailed: "Wystąpił błąd podczas tworzenia płatności.",
                paymentCreated: "Płatność utworzona, ale nieukończona. Skontaktuj się z Administratorem, aby sprawdzić status płatności.",
                paymentWasNotCreated: "Płatność nie została utworzona. Proszę spróbuj ponownie."
            },
            specialistSearch: {
                translatorAlreadyAdded: "Już wybrałeś Tłumacza!",
                selectAnotherTranslator: "Wybrać innego Tłumacza",
                administratorAlreadyAdded: "Już wybrałeś administratora konsyliuma!",
                selectAnotherAdministrator: "Wybierz innego administratora"
            },
            admin: {
                cashOutPage: {
                    minMessageLength: "Wiadomość musi zawierać więcej niż 5 znaków",
                }
            },
            formErrors: {
                required: "Wymagany!"
            },
            maxSpecialistsModal: {
                edit: "Edytować"
            }
        }
    },
};
