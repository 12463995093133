import React, { Component } from "react";
import AuthTypeSelectionModal from "./AuthTypeSelectionModal";
import SpecialitySelectionModal from "./SpecialitySelectionModal";
import { User } from "../../domain/models/User";

interface SpecModalRequestProps {
    user: User;
    handler?: () => Promise<void>;
    close: () => void;
    t(key?: string): string;
}

export default class BecameASpecialistModal extends Component<SpecModalRequestProps, any> {
    render() {
        return this.props.user.isAnonymous ? (
            <AuthTypeSelectionModal t={this.props.t} close={this.props.close} />
        ) : (
            <SpecialitySelectionModal
                close={this.props.close}
                userId={this.props.user.id}
                handler={this.props.handler}
                t={this.props.t}
            />
        );
    }
}
