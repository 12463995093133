import { Mapper } from "../../domain/mappers/mapper";
import { UserDTO } from "../../domain/models/dto/UserDTO";
import { User } from "../../domain/models/User";
import { userFactory } from "../models/UserModel";

export class UserMapper implements Mapper<User, UserDTO> {
    protected skipServerUtcToLocalConversion: boolean;

    constructor(skipServerUtcToLocalConversion: boolean) {
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
    }

    toDTO(user: User): UserDTO {
        if (!user) {
            return null;
        }
        return null;
    }
    fromDTO(userData: UserDTO): User {
        if (!userData) {
            return null;
        }
        return userFactory(
            {
                id: userData.id || "",
                balance: userData.balance || 0,
                isAnonymous: !!userData.isAnonymous,

                name: userData.name || "",
                surname: userData.surname || "",
                imageUrl: userData.imageUrl || "",
                languages: userData.languages || [""],
                timezone: userData.timezone,
            },
            this.skipServerUtcToLocalConversion
        );
    }
}

export function userMapperFactory(
    skipServerUtcToLocalConversion: boolean
): Mapper<User, UserDTO> {
    return new UserMapper(skipServerUtcToLocalConversion);
}
