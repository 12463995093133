import React from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { userServiceFactory } from "../../concilium/services/UserServiceImpl";
import { SpecialistType } from "../../domain/enums/SpecialistType";
import { User } from "../../domain/models/User";
import { UserService } from "../../domain/services/UserService";
import { UserSpecialistService } from "../../domain/services/UserSpecialistService";
import BecameASpecialistModal from "./BecameASpecialistModal";
import UsePromocodeModal from "./UsePromocodeModal";
import { allowUsePromocodes } from "../../Config";
import { userSpecialistServiceFactory } from "../../services/UserSpecialistService";

interface BecameASpecialistButtonState {
    user: User;
    isSpecialitySelected: boolean;
    specType: number;
}

interface BecameASpecialistButtonProperty {
    t(key?: string): string;
    buttonClassName?: string;
}

class BecameASpecialistButton extends React.Component<BecameASpecialistButtonProperty, BecameASpecialistButtonState> {
    userService: UserService;
    userSpecialistService: UserSpecialistService;
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            isSpecialitySelected: false,
            specType: 1,
        };
        this.userService = userServiceFactory();
        this.userSpecialistService = userSpecialistServiceFactory();
    }

    componentDidMount = async () => {
        this.setState({
            user: await this.userService.getCachedUser(),
        });
    };

    handleSpecialitySelection = async (specType: SpecialistType) => {
        await this.userSpecialistService.createUserSpecialist(specType);
        this.setState(
            {
                isSpecialitySelected: true,
            },
            () => {
                if (!allowUsePromocodes) {
                    window.location.replace("/spec/request");
                }
            }
        );
    };

    render = () => {
        const buttonClassName = this.props.buttonClassName || "header__link";
        const t = this.props.t;
        const SpecModalButton = (
            <div>
                <a className={buttonClassName} href="#">
                    {t("becameASpecialistButton.button")}
                </a>
            </div>
        );
        return (
            <React.Fragment>
                <Popup trigger={SpecModalButton} modal overlayStyle={{ zIndex: 999999 }}>
                    {(close) => (
                        <BecameASpecialistModal
                            user={this.state.user}
                            handler={this.handleSpecialitySelection.bind(this)}
                            close={close}
                            t={this.props.t}
                        />
                    )}
                </Popup>
                {allowUsePromocodes && (
                    <Popup
                        open={this.state.isSpecialitySelected}
                        modal
                        overlayStyle={{ zIndex: 999999 }}
                        closeOnEscape={false}
                    >
                        {(close) => <UsePromocodeModal t={this.props.t} close={close} />}
                    </Popup>
                )}
            </React.Fragment>
        );
    };
}

export default withTranslation()(BecameASpecialistButton);
